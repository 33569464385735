import React from 'react';
import backgroundImage from "../assets/images/chitech_abstract.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faCloud, faMobile, faGears, faShoppingCart, faCode, faHashtag, faVideo } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import albumImage from "../assets/images/AlbumCover 1.png";
import placeholderWeb from "../assets/images/UnierosScreenshot.png"; // Add placeholder images
import placeholderSocial from "../assets/images/podcasting.png";
import placeholderVideo from "../assets/images/video_computer_screen.png";

const Home = () => {
  const jumbotronStyle = {
    backgroundImage: `linear-gradient(rgba(30, 41, 59, 0.95), rgba(51, 65, 85, 0.9)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
    padding: "0 20px",
    position: "relative",
    transition: "all 0.3s ease",
  };

  const specialtyCardStyle = {
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    cursor: 'pointer',
    height: '100%',
    padding: '2rem',
    borderRadius: '12px',
    ':hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 10px 20px rgba(0,0,0,0.1)'
    }
  };

  const specialtyIconStyle = {
    color: '#2A9D8F',
    fontSize: '2.5rem',
    marginBottom: '1.5rem',
    filter: 'drop-shadow(0 4px 6px rgba(42, 157, 143, 0.2))'
  };

  const specialtyHeaderStyle = {
    fontSize: '1.5rem',
    fontWeight: '700',
    color: '#264653',
    marginBottom: '1rem',
    letterSpacing: '0.5px'
  };

  return (
    <div className="container-fluid p-0">
      {/* Hero Section */}
      <div className="jumbotron jumbotron-fluid" style={jumbotronStyle}>
        <div className="container">
          <h1 className="display-4">Web Development & Digital Marketing Solutions</h1>
          <p className="lead">Helping creators and businesses build their online presence</p>
          <a href="https://calendar.app.google/6hC9D3hPCW3taKr98" className="btn btn-primary btn-lg">Schedule a Consultation</a>
        </div>
      </div>

      {/* Specialties Section */}
      <section className="specialties py-5">
        <div className="container">
          <h2 className="text-center mb-5">Areas of Expertise</h2>
          <div className="row g-4">
            <div className="col-md-4">
              <div className="specialty-card p-4 bg-light rounded" style={specialtyCardStyle}>
                <FontAwesomeIcon 
                  icon={faCode} 
                  className="mb-4" 
                  style={specialtyIconStyle}
                />
                <h5 style={specialtyHeaderStyle}>Web Development & Maintenance</h5>
                <p className="text-muted">WordPress, React, API integrations</p>
                <div className="portfolio-preview mt-3">
                  <img src={placeholderWeb} alt="Web Development Example" className="img-fluid rounded mb-3" />
                  <ul className="list-unstyled">
                    <li>✓ Custom WordPress Themes</li>
                    <li>✓ React Applications</li>
                    <li>✓ E-commerce Solutions</li>
                  </ul>
                  {/* <Link to="/services/web-development" className="btn btn-outline-secondary mt-2">View Projects</Link> */}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="specialty-card p-4 bg-light rounded" style={specialtyCardStyle}>
                <FontAwesomeIcon 
                  icon={faHashtag} 
                  className="mb-4" 
                  style={specialtyIconStyle}
                />
                <h5 style={specialtyHeaderStyle}>Digital Marketing & Social Media</h5>
                <p className="text-muted">Growth strategies and management</p>
                <div className="portfolio-preview mt-3">
                  <img src={placeholderSocial} alt="Social Media Example" className="img-fluid rounded mb-3" />
                  <ul className="list-unstyled">
                    <li>✓ Content Strategy</li>
                    <li>✓ Social Media Management</li>
                    <li>✓ Analytics & Reporting</li>
                  </ul>
                  {/* <Link to="/services/digital-marketing" className="btn btn-outline-secondary mt-2">View Campaigns</Link> */}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="specialty-card p-4 bg-light rounded" style={specialtyCardStyle}>                <FontAwesomeIcon 
                  icon={faVideo} 
                  className="mb-4" 
                  style={specialtyIconStyle}
                />
                <h5 style={specialtyHeaderStyle}>Video & Podcast Support</h5>
                <p className="text-muted">Technical setup and editing</p>
                <div className="portfolio-preview mt-3">
                  <img src={placeholderVideo} alt="Video Production Example" className="img-fluid rounded mb-3" />
                  <ul className="list-unstyled">
                    <li>✓ Podcast Production</li>
                    <li>✓ Video Editing</li>
                    <li>✓ Technical Consulting</li>
                  </ul>
                  {/* <Link to="/services/media-production" className="btn btn-outline-secondary mt-2">View Productions</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Digital Products / Shop Section */}
      <section className="shop-section py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-5">Shop Digital Products</h2>
          <div className="row justify-content-center g-4">
            {/* Product 1 */}
            <div className="col-md-4">
              <div className="card text-center shadow-sm">
                <img src={albumImage} className="card-img-top" alt="Event Flyer Templates" />
                <div className="card-body">
                  <h5 className="card-title">Musician Six-pack Promo Template</h5>
                  <p className="card-text">Editable Canva templates for indie musicians.</p>
                  <a href="https://unieroschris.gumroad.com/l/tech" className="btn btn-primary">Buy on Gumroad</a>
                </div>
              </div>
            </div>

            {/* Product 2 */}
            {/* <div className="col-md-4">
              <div className="card text-center shadow-sm">
                <img src={brandingKitImage} className="card-img-top" alt="Podcast Branding Kit" />
                <div className="card-body">
                  <h5 className="card-title">Podcast Branding Kit</h5>
                  <p className="card-text">Custom overlays & thumbnails for your podcast.</p>
                  <a href="https://gumroad.com/YOURPRODUCTLINK" className="btn btn-primary">Buy on Gumroad</a>
                </div>
              </div>
            </div> */}
          </div>
          <div className="text-center mt-4">
            <Link to="/shop" className="btn btn-outline-dark">View All Products</Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
