import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const Shop = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">Shop Digital Products</h1>
      
      <div className="row g-4">
        {/* Product 1 */}
        <div className="col-md-4">
          <div className="card text-center shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Musician Six-pack Promo Template</h5>
              <p className="card-text">Editable Canva templates for indie musicians.</p>
              <a href="https://unieroschris.gumroad.com/l/tech" className="btn btn-primary">Buy on Gumroad</a>
            </div>
          </div>
        </div>

        {/* Product 2 */}
        <div className="col-md-4">
          <div className="card text-center shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Podcast Branding Kit</h5>
              <p className="card-text">Custom overlays & thumbnails for your podcast.</p>
              <a href="https://gumroad.com/YOURPRODUCTLINK" className="btn btn-primary">Buy on Gumroad</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
